import Navbar from "./Navbar";

const Header = () => {
  return (
   

      <Navbar/>
   
  )
}

export default Header